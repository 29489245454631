import React, { useState, useEffect, Fragment } from "react";
import { ThemeProvider } from "styled-components";
import Sticky from "react-stickynode";
import { appTheme } from "common/src/theme/app";
import { GlobalStyle, AppWrapper } from "common/src/containers/App/app.style";
import { ResetCSS } from "common/src/assets/css/style";
import Navbar from "common/src/containers/App/Navbar";
import Footer from "common/src/containers/App/Footer";

import Container from "../../../common/src/components/UI/Container";

import { DrawerProvider } from "common/src/contexts/DrawerContext";

import "@redq/reuse-modal/es/index.css";
import SEO from "../components/seo";

function getSize() {
  return {
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    outerHeight: window.outerHeight,
    outerWidth: window.outerWidth
  };
}

function useWindowSize() {
  let [windowSize, setWindowSize] = useState(getSize());

  function handleResize() {
    setWindowSize(getSize());
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowSize;
}

export default () => {
  const size = process.browser && useWindowSize();

  return (
    <ThemeProvider theme={appTheme}>
      <Fragment>
        <SEO
          title="Haveacoffeeshop | shop where you are"
          metaDescription="A digital coffeeshop finder and online product ordering service brought to mobile devices."
        />
        <ResetCSS />
        <GlobalStyle />
        <AppWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar
                deactivated
                extraLinks={[
                  { title: "Home", to: "/" },
                  { title: "Datenschutzerklärung", to: "/datenschutz" }
                ]}
              />
            </DrawerProvider>
          </Sticky>
          <Container>
            <h1>Impressum</h1>
            <h3>HERAUSGEBER</h3>
            <p>
              C4AP – Concepts for Asset Protection <br />
              Karlsruher Str. 20 <br />
              69126 Heidelberg <br />
              E-Mail: thomas.bahr@c4ap.de https://www.c4ap.de
              <br />
            </p>
            <h3>ANBIETERKENNZEICHNUNG GEMÄSS §6 TELEDIENSTGESETZ (TDG)</h3>
            <p>
              C4AP – Concepts for Asset Protection
              <br />
              Geschäftsführung: Thomas Bahr <br />
              Karlsruher Str. 20 <br />
              <br />
              69126 Heidelberg Ust.-IdNr.: 32011/31082 Finanzamt Heidelberg
              <br />
            </p>
            <h3>HAFTUNGSAUSSCHLUSS</h3>
            <p>
              Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine
              Haftung für die Inhalte externer Links. Für den Inhalt der
              verlinkten Seiten sind ausschließlich deren Betreiber
              verantwortlich. C4AP übernimmt keinerlei Haftung für Ansprüche im
              Zusammenhang mit diesen Internetseiten.Alle Rechte vorbehalten.
              Alle Texte, Grafiken, Bilder, Dateien sowie der strukturelle
              Aufbau dieser Internetseiten sind geistiges Eigentum von C4AP und
              unterliegen dem Urheberrecht. Die Weitergabe, Veränderung,
              gewerbliche Nutzung oder Verwendung in anderen Websites oder
              Medien bedarf der vorherigen schriftlichen Zustimmung von C4AP.
              <br />
            </p>
            <h3>INHALTE</h3>
            <p>
              Der Autor übernimmt keinerlei Gewähr für die Aktualität,
              Korrektheit, Vollständigkeit oder Qualität der bereitgestellten
              Informationen. Haftungsansprüche gegen den Autor, welche sich auf
              Schäden materieller oder ideeller Art beziehen, die durch die
              Nutzung oder Nichtnutzung der dargebotenen Informationen bzw.
              durch die Nutzung fehlerhafter und unvollständiger Informationen
              verursacht wurden, sind grundsätzlich ausgeschlossen, sofern
              seitens des Autors kein nachweislich vorsätzliches oder grob
              fahrlässiges Verschulden vorliegt.
              <br />
              <br /> Alle Angebote sind freibleibend und unverbindlich. Der
              Autor behält es sich ausdrücklich vor, Teile der Seiten oder das
              gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu
              ergänzen, zu löschen oder die Veröffentlichung zeitweise oder
              endgültig einzustellen. <br />
            </p>
            <h3>LINKS</h3>
            <p>
              Bei direkten oder indirekten Verweisen auf fremde Internetseiten
              (sog. Links), die außerhalb des Verantwortungsbereiches des Autors
              liegen, würde eine Haftungsverpflichtung ausschließlich in dem
              Fall in Kraft treten, in dem der Autor von den Inhalten Kenntnis
              hat und es ihm technisch möglich und zumutbar wäre, die Nutzung im
              Falle rechtswidriger Inhalte zu verhindern. <br />
              <br />
              Der Autor erklärt hiermit ausdrücklich, dass zum Zeitpunkt der
              Linksetzung keine illegalen Inhalte auf den zu verlinkenden Seiten
              erkennbar waren. Auf die aktuelle und zukünftige Gestaltung, die
              Inhalte oder die Urheberschaft der gelinkten/verknüpften Seiten
              hat der Autor keinerlei Einfluss. Deshalb distanziert er sich
              hiermit ausdrücklich von allen Inhalten aller gelinkten
              /verknüpften Seiten, die nach der Linksetzung verändert wurden.
              <br />
              <br />
              Diese Feststellung gilt für alle innerhalb des eigenen
              Internetangebotes gesetzten Links und Verweise sowie für
              Fremdeinträge in vom Autor eingerichteten Gästebüchern,
              Diskussionsforen und Mailinglisten. Für illegale, fehlerhafte oder
              unvollständige Inhalte und insbesondere für Schäden, die aus der
              Nutzung oder Nichtnutzung solcherart dargebotener Informationen
              entstehen, haftet allein der Anbieter der Seite, auf welche
              verwiesen wurde, nicht derjenige, der über Links auf die jeweilige
              Veröffentlichung lediglich verweist. <br />
              <br />
            </p>
            <h3>TECHNISCHE UMSETZUNG UND BETREUUNG</h3>
            <p>
              Hyperbrand – Webdesign Studio <br />
              Thorsten Schraut Friedberger Anlage 8 <br />
              60314 Frankfurt am Main <br />
              <br />
              www.hyperbrand.de © C4AP | Stand Mai 2017
            </p>
          </Container>
          <Footer />
        </AppWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
